const $ = window.jQuery;
const jQuery = $;

window.addEventListener('load', function() {
    var countySelect = $('#select-county');
    var originalWindowSize = $(window).width();
    var originalSelectSize = $(countySelect).attr('size');
    var mobileBreakpoint = 767;

    function resizeSelect(){
        var windowSize = jQuery(window).width();
        if (windowSize > mobileBreakpoint) {
            jQuery(countySelect).attr('size', originalSelectSize);
        }
        if (windowSize <= mobileBreakpoint){
            jQuery(countySelect).attr('size', '1');
        }
    }

    resizeSelect();
    jQuery(window).resize(function(){
        resizeSelect();
    } );

    $("#ncrc_chart #printButton").click(function() {
        ga('send', 'event', 'Button', 'Print', 'ncrc earned chart - print');
    });

    $('#ncrc_chart #exportButton').click(function() {
        ga('send', 'event', 'Button', 'Download', 'ncrc earned chart - download');
    });

    $("#employer_chart #printButton_2").click(function() {
        ga('send', 'event', 'Button', 'Print', 'supporting emp chart - print');
    });

    $('#employer_chart #exportButton_2').click(function() {
        ga('send', 'event', 'Button', 'Download', 'supporting emp chart - download');
    });
});